import React, { lazy } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { Routing } from "./constants/routing";

/* Import lazy routes */
const MainLayout = lazy(() => import("../layouts/MainLayout"));
const TextComparator = lazy(
  () => import("../pages/TextComparator/TextComparer")
);
interface IRoute {
  path: string;
  component: any;
  isPrivateRoute: boolean;
}

const routes: IRoute[] = [
  {
    path: Routing.TextComparator,
    component: <TextComparator />,
    isPrivateRoute: false,
  },
];

export const AppRouting: React.FC = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route
          path={routes
            .filter((route) => !route.isPrivateRoute)
            .map((x) => x.path)}
        >
          <MainLayout>
            <Switch>
              {routes
                .filter((route) => !route.isPrivateRoute)
                .map(({ path, component }, key) => (
                  <Route
                    exact
                    path={path}
                    component={() => {
                      return component;
                    }}
                    key={key}
                  />
                ))}
            </Switch>
          </MainLayout>
        </Route>
        <Redirect to={{ pathname: Routing.TextComparator }} />
      </Switch>
    </BrowserRouter>
  );
};
